import React, {Component} from 'react';
import {Container} from "reactstrap"
import styled from "styled-components"
import {media} from "utils/Media";
import seeItSafety from "../../images/See-It-Safely-Logo.png"
import Social from "../shared/Social"
import {Link} from "gatsby"

const date = new Date();

const Wrapper = styled.div`
    position: relative;
    z-index: 10;
`

const Content = styled.div`
    padding: 2rem 0;
    margin: 0 auto;
    text-align: center;

    @media ${media.md} {
    }
    @media(max-width: 767px) {
      padding-bottom: 150px;
    }
    p {
        font-size: 18px;

        .small {
            font-size: 14px;
            font-weight: 300;
        }

        a {
            font-weight: 300;
        }
    }
`

class Footer extends Component {
    render() {
        return (
            <Wrapper>
            <Container>
                <Content>
                    {/* <a href="https://officiallondontheatre.com/see-it-safely/" target="_blank" rel="noreferrer noopener"
                       className="p-0 pb-4">
                        <img src={seeItSafety} alt="See It Safety" title="See It Safety" className="seeItSafety"/>
                    </a> */}

                    <Social
                        facebook="https://www.facebook.com/MousetrapLondon/"
                        instagram="https://www.instagram.com/themousetraplondon/"
                        twitter="https://twitter.com/MousetrapLondon"
                    />
                    <p>St. Martin's Theatre, <br className="d-md-none"/>West St, London WC2H 9NZ<br/>
                        <span className="small">
                            &copy; The Mousetrap {date.getFullYear()} <br className="d-md-none"/> <span
                            className="d-none d-md-inline">|</span>  <Link
                            to="/privacy-policy/">Privacy Policy</Link> <br className="d-md-none"/> <span
                            className="d-none d-md-inline">|</span>  <Link
                            to="/cookies-policy/">Cookie Policy</Link> <br className="d-md-none"/> <span
                            className="d-none d-md-inline">|</span>  <Link to="/terms-and-conditions/">Terms & Conditions</Link> <br
                            className="d-md-none"/>
                        </span></p>
                </Content>
            </Container>
            </Wrapper>
        );
    }
}

export default Footer;
