import React from "react"
import styled from "styled-components"
import facebook from "images/icons/facebook-white.svg"
import instagram from "images/icons/instagram-white.svg"
import twitter from "images/icons/twitter-white.svg"
import {media} from "utils/Media"

const SocialWrap = styled.div`
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: center;

	a {
		display: inline-block;
		padding: 0 0.5rem;
		width: 60px;
		
		@media ${media.md} {
		    padding: 0 .75rem;
		}
		
		&:first-of-type {
		    padding: 0 0.5rem 0 0;
		    
		    @media ${media.md} {
		        padding: 0 0.75rem;
		    }
		}
		
		img {
		    max-height: 24px;
		    width: 100%;
		}
	}
`

const Social = (props) => (
    <SocialWrap {...props}>
        <a href={props.facebook} target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="Facebook" />
        </a>
        <a href={props.instagram} target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="Instagram" />
        </a>
        <a href={props.twitter} target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="Twitter" />
        </a>
    </SocialWrap>
)

export default Social